import * as msal from '@azure/msal-browser';

// const AppConfigs = {
//   tenant: 'cxportal.onmicrosoft.com',
//   clientId: 'eb0f91d7-0179-411a-a357-0dfe7e9cff52',
//   authority:
//     'https://cxportal.b2clogin.com/cxportal.onmicrosoft.com/b2c_1a_lwcportal_signin_policy_vsgna/',
//   b2cScopes:
//     'https://cxportal.b2clogin.com/eb0f91d7-0179-411a-a357-0dfe7e9cff52/user.read',
//   redirectUrl: 'http://localhost:3000/',
//   signinPolicy: 'B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGNA',
//   resetPasswordPolicy: 'B2C_1A_PasswordReset',
//   changePasswordPolicy: 'B2C_1A_ProfileEditPasswordChange',
//   b2cLoginURL: 'https://cxportal.b2clogin.com',
// };
const locationURl = window.location.origin;
const policy = localStorage.getItem('policy');

//****UAT***//

// const AppConfigs = {
//   tenant: 'cxportaluat.onmicrosoft.com',
//   clientId: '7e9f9a1e-0e38-4eb4-929c-927c38b5a349',
//   tenantAuthority: 'cxportaluat.b2clogin.com',
//   b2cScopes:
//     'https://cxportaluat.b2clogin.com/7e9f9a1e-0e38-4eb4-929c-927c38b5a349/user.read',
//   redirectUrl: 'https://uat-vsgproductregistration.vsgdover.com/',
//   // redirectUrl: 'https://rotary-registration.com',
//   signinPolicy: 'B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGNA_COMBINED', //'B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGNA',
//   resetPasswordPolicy: 'B2C_1A_PasswordReset',
//   changePasswordPolicy: 'B2C_1A_ProfileEditPasswordChange',
//   b2cLoginURL: 'https://cxportaluat.b2clogin.com',
// };
// ***test***//
// const AppConfigs = {
//   tenant: 'cxportal.onmicrosoft.com',
//   clientId: 'eb0f91d7-0179-411a-a357-0dfe7e9cff52',
//   // clientId: '34f42da5-935b-4641-a662-4a2d44a3ace5',
//   tenantAuthority: 'cxportal.b2clogin.com',
//   b2cScopes: ['openid', 'profile'],
//   redirectUrl: 'https://test-vsgproductregistration.vsgdover.com/',
//   // redirectUrl: 'https://rotary-registration.com',
//   // redirectUrl: `${locationURl}`,
//   signinPolicy: 'B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGNA_COMBINED',
//   // signinPolicy: 'B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGPR',
//   resetPasswordPolicy: 'B2C_1A_PasswordReset',
//   changePasswordPolicy: 'B2C_1A_ProfileEditPasswordChange',
//   b2cLoginURL: 'https://cxportal.b2clogin.com',
// };

// ***Prod****//
const AppConfigs = {
  tenant: 'cxportalprod.onmicrosoft.com',
  clientId: '91b9fae7-2bff-435a-a24e-da9cafff89af',
  tenantAuthority: 'cxportalprod.b2clogin.com',
  b2cScopes:
    'https://cxportalprod.onmicrosoft.com/91b9fae7-2bff-435a-a24e-da9cafff89af/user.read',
  // redirectUrl: `${locationURl}`,
  // redirectUrl: 'https://vsgproductregistration.vsgdover.com/',
  redirectUrl: 'https://rotary-registration.com',
  signinPolicy: 'B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGPR',
  resetPasswordPolicy: 'B2C_1A_PasswordReset',
  changePasswordPolicy: 'B2C_1A_ProfileEditPasswordChange',
  b2cLoginURL: 'https://cxportalprod.b2clogin.com/',
};
const msalConfig = {
  auth: {
    clientId: AppConfigs.clientId,
    authority: `https://${AppConfigs.tenantAuthority}/tfp/${AppConfigs.tenant}/${AppConfigs.signinPolicy}`,
    redirectUri: AppConfigs.redirectUrl,
    // knownAuthorities: ['https://cxportal.b2clogin.com'],
    // knownAuthorities: ['https://cxportaluat.b2clogin.com'],
    knownAuthorities: ['https://cxportalprod.b2clogin.com/'],
    postLogoutRedirectUri: locationURl,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};
export default function getSsoInstence() {
  return new msal.PublicClientApplication(msalConfig);
}
export const loginRequest = {
  scopes: ['User.Read'],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
